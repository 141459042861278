<template>
  <div class="content">
    <div class="container">
      <div class="row">
        <div class="col-sm-12" style="text-align: justify">
          <div class="headline style-3">
            <h5>Biuro Storno</h5>
            <h2>Historia</h2>
          </div>
          <div>
            <img src="images/h.jpg" width="190" height="190" class="wow fadeInRight animated" style="float:right">
            <p class="wow fadeInDown animated">
              Powstanie biur księgowych ma nierozerwalny związek
              z przemianami ustrojowymi w Polsce. Kiedyś struktura gospodarki
              naszego kraju opierała się na sektorze wielkich firm, kombinatów,
              zatrudniających setki tysiące osób. Małe firmy, przedsiębiorstwa
              były gnębione przez reżim&nbsp; komunistyczny. Średnia a zwłaszcza mała
              przedsiębiorczość nie miała racji bytu.&nbsp; Taka struktura gospodarki
              wielkie molochy gospodarcze posiadały własne bardzo rozbudowane
              działy księgowości i kadr. Zarząd firmy miał oparcie w wiedzy i
              doświadczeniu swych służb księgowo , ekonomiczno kadrowych.
            </p>
            <p class="wow fadeInLeft animated">
              Zmiany jakie nastąpiły w
              Polsce na początku lat 90 zmieniły strukturę gospodarki, w miejsce
              niewielu wielkich i dużych firm, zaczęły powstawać małe i średnie
              przedsiębiorstwa, często rodzinne, które aby przetrwać w warunkach
              gospodarki rynkowej, często z powodu konieczności cięcia kosztów,
              oszczędności nie mogły pozwolić sobie na zatrudnienie kadrowca
              księgowego. Była to w zasadzie często droga donikąd, bez
              odpowiedniego wsparcia fachowego bardzo często takie firmy prędzej
              czy później wpadały w konflikt czy to z Urzędem Skarbowym, Zakładem
              Ubezpieczeń Społecznych, czy też Państwową Inspekcją Pracy. Aby
              wyjść oczekiwaniom tych właśnie małych przedsiębiorców, zaczęły
              powstawać biura księgowe. Jednym z pierwszych takich biur i chyba
              jednym z niewielu z tych pierwszych, które przetrwało do dnia
              dzisiejszego jest założone 1 lutego 1992 roku Biuro Rachunkowe Poznań
              "STORNO".
            </p>
          </div>
          <div class="wow fadeInUp" style="text-align: center">
            <a href="http://www.startbiznesdp.pl" target="_blank"><img
              src="/images/startbiznesdp.png"
              alt="Start Biznes"
            ></a>
          </div>
          <div class="wow fadeInUp logo-zus" style="text-align: center">
            <a href="https://www.eskladka.pl/" target="_blank">
              <table>
                <tr>
                  <td class="logo-zus__img">
                    <img src="images/logo-zus.png" width="99" height="59" alt="ZUS">
                  </td>
                  <td class="logo-zus__text">
                    Sprawdź<br>numer<br>rachunku ZUS
                  </td>
                </tr>
              </table>
            </a>
          </div>
          <div class="logo-epit">
            <a href="https://www.podatki.gov.pl/pit/twoj-e-pit/" class="ow fadeInUp " target="_blank">Twój <span>e</span>-PIT</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  head: {
    title: 'Strona główna - Biuro rachunkowe Poznań "STORNO"'
  }
}
</script>
